<template>
    <el-main class="com-eTrip-section intervention-order-detail" :class="showFooter?'section-has-footer':''">
        <div class="eTrip-section-cont" v-if="record">
            <h3>
                <span :class="record.complaintStatus | formatDesc(orderComplaintsStatusOptions,'value','color')">
                {{ record.complaintStatus | formatDesc(orderComplaintsStatusOptions) }}
                </span>
                | 投诉详情
            </h3>
            <el-descriptions :column="1" title="订单信息">
                <el-descriptions-item label="订单号">
                    <el-link type="primary" :href="'#/userCarList?orderNo='+record.orderNo">{{
                            record.orderNo
                        }}
                    </el-link>
                </el-descriptions-item>
                <el-descriptions-item label="车辆车牌">{{ record.carNum }}
                </el-descriptions-item>
                <el-descriptions-item label="司机姓名手机">
                    {{ record.driverName }}/{{ record.driverPhone }}
                </el-descriptions-item>
            </el-descriptions>
            <div style="border-top: 1px solid #D8DCE6;padding-top: 20px;margin-top: 20px ">
                <el-descriptions :column="1" title="投诉信息">
                    <el-descriptions-item label="投诉日期">
                        {{ record.createDate | dateCus('yyyy-MM-dd hh:mm') }}
                    </el-descriptions-item>
                    <el-descriptions-item label="投诉类型">
                        {{ record.complaintType | formatDesc(complaintTypeOptions) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="投诉事由">
                        {{ record.complaintInfo }}
                    </el-descriptions-item>
                    <el-descriptions-item label="处理结果" v-if="record.complaintStatus===3">
                        {{ record.result }}
                    </el-descriptions-item>
                    <el-descriptions-item label="凭证图片">
                        <div v-if="record.complaintPhoto.length" style="display: flex">
                            <div v-for="vm in record.complaintPhoto" :key="vm"
                                 style="position: relative;margin-right: 20px">
                                <el-image
                                    style="width: 120px;height: 120px;"
                                    :src="vm"
                                    :preview-src-list="[vm]"></el-image>
                            </div>
                        </div>
                        <span v-else>无</span>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
        <el-footer class="footer-bar" v-if="showFooter">
            <div class="footer-buttons">
                <el-button type="primary" style="width: 110px" v-if="record.complaintStatus===1"
                           @click="handleStart(0)" :loading="loading">
                    开始处理
                </el-button>
                <el-button type="primary" style="width: 110px;margin-left: 10px" v-if="record.complaintStatus===2"
                           @click="handleEnd(1)" :loading="loading">
                    处理完结
                </el-button>
            </div>
        </el-footer>
    </el-main>
</template>
<script>
import {defineComponent} from 'vue'

import {orderComplaintsStatusOptions, complaintTypeOptions} from '@/data/orderComplaints'
import {complaintContactApi, getComplaintDetailApi, complaintResultApi} from '@/www/urls/orderComplaints'

export default defineComponent({
    data() {
        return {
            loading: false,
            record: undefined,
            orderComplaintsStatusOptions,
            complaintTypeOptions,
            type: 0
        }
    },
    computed: {
        showFooter() {
            return this.record && this.type != 0 && [1, 2].includes(this.record.complaintStatus)
        }
    },
    mounted() {
        this.getData(this.$route.query.id)
        this.type = this.$route.query.type
    },
    methods: {
        async getData(id) {
            try {
                this.loading = true
                const ret = await getComplaintDetailApi(id)
                if (ret.success) {
                    this.record = {
                        ...ret.data,
                        complaintPhoto: ret.data.complaintPhoto.split(';').filter((url) => url)
                    }
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleEnd() {
            try {
                this.loading = true;
                const {value} = await this.$prompt('确认投诉已处理完结？', '处理完结', {
                    inputPlaceholder: '请输入处理结果',
                    inputType: 'textarea',
                    maxlength: 10,
                    showWordLimit: true,
                    inputValidator(val) {
                        if (!val)
                            return '请输入处理结果'
                        if (val.length > 200)
                            return '限制输入200个字符'
                    }
                })
                const ret = await complaintResultApi({
                    id: this.record.id,
                    result: value
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.getData(this.record.id);
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleStart() {
            try {
                this.loading = true;
                await this.$confirm('确定开始处理？', '提示');
                const ret = await complaintContactApi({
                    id: this.record.id
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.getData(this.record.id)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
})
</script>


<style scoped lang="scss">
.eTrip-section-cont {
    padding: 20px;
    background-color: #fff;

    h3 {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 28px;
    }

    &::v-deep {
        .el-descriptions-item__label, .el-descriptions-item__content {
            font-size: 14px;
            color: #333333;
            font-weight: 400;
        }
    }

    .table-title {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        font-weight: 400;
        margin: 20px 0 10px 0;
    }


}

.footer-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: flex-end;
    box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.06);
    width: calc(100% + 60px);
    height: 100%;
    margin-left: -30px;
    padding: 0 30px;
}
</style>
